import React, { useCallback, useEffect, useState } from "react";
import { Typography, Button, Modal } from "antd";
import {
  ProTable,
  PageContainer,
  ProFormText,
} from "@ant-design/pro-components";
import dayjs from "dayjs";
import { useNavigate } from "react-router";
import {
  DeleteFilled,
  FileTextFilled,
  ExclamationCircleFilled,
  UndoOutlined,
  EditFilled,
} from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
import { deleteClub, getTickets } from "../../../api";
import {
  SortingDate,
  SortingNumber,
  SortingText,
} from "../../../utils/sortingData";
import { useParams, useLocation } from "react-router-dom";
import { parseQueryString } from "../../../utils/parseQueryString";
import { createQueryStringUrl } from "../../../utils/createQueryStringUrl";

const { Text } = Typography;

const ENUM_STATUS = {
  WAIT_FOR_CHECKIN: {
    text: "WAIT_FOR_CHECKIN",
  },
  CHECKED_IN: {
    text: "CHECKED_IN",
  },
  EXPIRED: {
    text: "EXPIRED",
  },
  CANCELED: {
    text: "CANCELED",
  },
  LATE_PAYMENT: {
    text: "LATE_PAYMENT",
  },
};

const ENUM_PAYMENT_STATUS = {
  WAITING_FOR_PAYMENT: {
    text: "WAITING_FOR_PAYMENT",
  },
  SOLD: {
    text: "SOLD",
  },
  EXPIRED: {
    text: "EXPIRED",
  },
};

const ENUM_IS_GENERATED = {
  NORMAL: {
    text: "NORMAL",
  },
  FREE: {
    text: "FREE",
  },
};

const Tickets = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = useForm();
  const { search } = useLocation();
  const queryString = parseQueryString(search);
  const customQueryString = {
    paymentStatus: queryString?.paymentStatus || null,
    status: queryString?.status || null,
    ticketNumber: queryString?.ticketNumber || null,
    ticketIntent: queryString?.ticketIntent || null,
    orderNumber: queryString?.orderNumber || null,
    userInformation: queryString?.userInformation || null,
    // startDate: queryString?.startDate || null,
    // endDate: queryString?.endDate || null,
  };

  const [tickets, setTickets] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState();
  const [isShowModal, setIsShowModal] = useState(false);
  const [filterTickets, setFilterTickets] = useState(customQueryString);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(null);

  const handleToggleDeleteModal = (record) => {
    if (!isShowModal) {
      setSelectedRecord(record);
    }

    setIsShowModal((prevState) => !prevState);
  };

  const fetchTickets = useCallback(async () => {
    try {
      createQueryStringUrl(filterTickets);
      form.setFieldsValue(filterTickets);

      const response = await getTickets(
        id,
        filterTickets,
        currentPage,
        pageSize
      );

      setTickets(response.results);
      setTotalItems(response.total);
    } catch (error) {
      console.log("Error", error);
    }
  }, [filterTickets, form, id, currentPage, pageSize]);

  const handleSubmitModal = async () => {
    try {
      const response = selectedRecord?.deleted
        ? console.log("Restored record")
        : await deleteClub(selectedRecord?.id);

      if (response.status === 200) {
        await fetchTickets();
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      // handleCancel();
      handleToggleDeleteModal();
    }
  };

  const handleSubmitFilter = (params) => {
    const formValues = form.getFieldsValue();

    setFilterTickets({
      ...filterTickets,
      paymentStatus: params?.paymentStatus || formValues?.paymentStatus || null,
      ticketNumber: params?.ticketNumber || formValues?.ticketNumber || null,
      status: params?.status || formValues?.status || null,
      ticketIntent: params?.ticketIntent || formValues?.ticketIntent || null,
      orderNumber: params?.orderNumber || formValues?.orderNumber || null,
      userInformation:
        params?.userInformation || formValues?.userInformation || null,
      // startDate:
      //   params?.createdAt?.[0] && formValues?.createdAt?.[0]
      //     ? dayjs(params?.createdAt?.[0]).toISOString() ??
      //       dayjs(formValues?.createdAt?.[0]).toISOString()
      //     : null,
      // endDate:
      //   params?.createdAt?.[1] && formValues?.createdAt?.[1]
      //     ? dayjs(params?.createdAt?.[1]).toISOString() ??
      //       dayjs(formValues?.createdAt?.[1]).toISOString()
      //     : null,
    });

    setCurrentPage(1);
  };

  const handleResetFilter = () => {
    setFilterTickets({
      paymentStatus: null,
      status: null,
      ticketNumber: null,
      ticketIntent: null,
      orderNumber: null,
      userInformation: null,
      // startDate: null,
      // endDate: null,
    });
    setCurrentPage(1);
  };

  useEffect(() => {
    fetchTickets();
  }, [fetchTickets]);

  const tableColumns = [
    {
      key: "userInformation",
      title: "User information",
      hideInTable: true,
      renderFormItem: (
        _,
        { type, defaultRender, formItemProps, fieldProps, ...rest },
        form
      ) => {
        if (type === "form") {
          return null;
        }
        return (
          <ProFormText
            name="searchFull"
            placeholder="Enter name, email or phone number"
          />
        );
      },
    },
    {
      key: "orderNumber",
      title: "Order number",
      dataIndex: ["order", "orderNumber"],
      sorter: (current, next) =>
        SortingText(current?.order?.orderNumber, next?.order?.orderNumber),
    },
    {
      key: "ticketNumber",
      title: "Ticket number",
      dataIndex: "ticketNumber",
      width: 350,
      sorter: (current, next) =>
        SortingText(current.ticketNumber, next.ticketNumber),
    },
    {
      key: "generator",
      title: "Generator name",
      dataIndex: "generator",
      sorter: (current, next) =>
        SortingText(current?.generator?.fullName, next?.generator?.fullName),
      render: (_, record) => {
        return <Text>{record?.generator?.fullName ?? "-"}</Text>;
      },
    },
    {
      key: "coupon",
      title: "Coupon Code",
      search: false,
      dataIndex: ["coupon", "couponCode"],
      sorter: (current, next) =>
        SortingText(current.coupon.couponCode, next.coupon.couponCode),
    },
    {
      key: "discount",
      title: "Discounted Price",
      search: false,
      dataIndex: "discount",
      render: (_, record) => {
        return <Text>{(record.price - record.discount).toFixed(2)}</Text>;
      },
    },
    {
      key: "price",
      title: "Price",
      search: false,
      dataIndex: "price",
      sorter: (current, next) => SortingText(current.price, next.price),
      render: (_, record) => {
        return <Text>{record.price.toFixed(2)}</Text>;
      },
    },
    {
      key: "username",
      title: "User name",
      dataIndex: ["user", "fullName"],
      search: false,
      sorter: (current, next) =>
        SortingText(current?.user?.fullName, next?.user?.fullName),
    },
    {
      key: "email",
      title: "User email",
      dataIndex: ["user", "email"],
      search: false,
      sorter: (current, next) =>
        SortingText(current?.user?.email, next?.user?.email),
    },
    {
      key: "phoneNumber",
      title: "User phone",
      dataIndex: ["user", "phoneNumber"],
      search: false,
      sorter: (current, next) =>
        SortingNumber(current?.user?.phoneNumber, next?.user?.phoneNumber),
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      search: false,
      sorter: (current, next) => SortingText(current.status, next.status),
      valueEnum: ENUM_STATUS,
    },
    {
      key: "paymentStatus",
      title: "Payment status",
      dataIndex: "paymentStatus",
      sorter: (current, next) =>
        SortingText(current.paymentStatus, next.paymentStatus),
      valueEnum: ENUM_PAYMENT_STATUS,
    },
    {
      key: "ticketIntent",
      title: "Ticket intention",
      dataIndex: "ticketIntent",
      sorter: (current, next) =>
        SortingText(current.ticketIntent, next.ticketIntent),
      valueEnum: ENUM_IS_GENERATED,
    },
    {
      key: "ticketType",
      title: "Ticket Type",
      dataIndex: "ticketType",
      render: (_, record) => {
        return <Text>{record?.ticketType?.nameEn || "--"}</Text>;
      },
    },
    {
      key: "createdAt",
      title: "Created At",
      dataIndex: "createdAt",
      valueType: "dateTimeRange",
      search: false,
      fieldProps: {
        placeholder: ["Start time", "End time"],
        showTime: { format: "HH:mm" },
      },
      sorter: (current, next) => SortingDate(current.createdAt, next.createdAt),
      render: (_, record) => {
        return (
          <Text>
            {dayjs(record.createdAt)
              .tz("Asia/Kuwait")
              .format("YYYY/MM/DD HH:mm")
              .toString()}
          </Text>
        );
      },
    },
    {
      key: "updatedAt",
      title: "Updated At",
      dataIndex: "updatedAt",
      hideInTable: true,
      search: false,
      sorter: (current, next) => SortingDate(current.updatedAt, next.updatedAt),
      render: (_, record) => {
        return <Text>{dayjs(record.updatedAt).fromNow()}</Text>;
      },
    },
    {
      key: "actions",
      title: "Actions",
      search: false,
      width: 100,
      render: (_, record) => [
        <div key={record.id} className="actions-wrapper">
          <Button
            className="icon-button"
            icon={<FileTextFilled />}
            onClick={() => navigate(`/tickets/${record?.id}`)}
          />

          <Button
            className="icon-button"
            icon={<EditFilled />}
            onClick={() => navigate(`/tickets/${record?.id}/edit`)}
          />

          <Button
            className="icon-button"
            icon={record?.deleted ? <UndoOutlined /> : <DeleteFilled />}
            onClick={() => handleToggleDeleteModal(record)}
          />
        </div>,
      ],
    },
  ];

  return (
    <PageContainer title="All Tickets">
      {isShowModal && (
        <Modal
          open={isShowModal}
          onOk={() => handleSubmitModal()}
          // onCancel={handleCancel}
          onCancel={handleToggleDeleteModal}
          centered
        >
          <div className="delete-content">
            <ExclamationCircleFilled style={{ fontSize: "48px" }} />
            {selectedRecord?.deleted ? (
              <p>Are you sure to restore this record?</p>
            ) : (
              <p>Are you sure to delete this record?</p>
            )}
          </div>
        </Modal>
      )}

      <ProTable
        rowKey="id"
        request={({ pageSize, current }, sort, filter) => {
          setPageSize(pageSize);

          return {
            tickets,
            // success,
            // total,
            filter,
          };
        }}
        onSubmit={(params) => handleSubmitFilter(params)}
        onReset={handleResetFilter}
        // onRow={(record) => {
        //   return {
        //     onClick: () => {
        //       navigate(`/tickets/${record?.id}`);
        //     },
        //   };
        // }}
        scroll={{ x: 1400, y: 500 }}
        columns={tableColumns}
        dataSource={tickets}
        pagination={{
          pageSize: pageSize,
          total: totalItems,
          onChange: (page) => setCurrentPage(page),
        }}
        search={{
          form: form,
          labelWidth: "auto",
        }}
      ></ProTable>
    </PageContainer>
  );
};

export default Tickets;
