import {
  PageContainer,
  ProFormText,
  ProTable,
} from "@ant-design/pro-components";
import React, { useCallback, useEffect, useState } from "react";
import { SortingDate, SortingText } from "../../../utils/sortingData";
import { Button, Typography } from "antd";
import dayjs from "dayjs";
import { getEventOrders } from "../../../api";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "antd/lib/form/Form";
import { parseQueryString } from "../../../utils/parseQueryString";
import { createQueryStringUrl } from "../../../utils/createQueryStringUrl";
import { EditFilled } from "@ant-design/icons";
const { Text } = Typography;

const EventOrders = () => {
  const { id } = useParams();
  const [form] = useForm();
  const navigate = useNavigate();

  const { search } = useLocation();
  const queryString = parseQueryString(search);
  const customQueryString = {
    keyWord: queryString?.keyWord || null,
    orderNumber: queryString?.orderNumber || null,
    status: queryString?.status || null,
    // startDate: queryString?.startDate || null,
    // endDate: queryString?.endDate || null,
  };

  const [orders, setOrders] = useState([]);
  const [filterOrders, setFilterOrders] = useState(customQueryString);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(null);

  const fetchOrders = useCallback(async () => {
    try {
      createQueryStringUrl(filterOrders);
      form.setFieldsValue(filterOrders);

      const response = await getEventOrders(
        id,
        filterOrders,
        currentPage,
        pageSize
      );

      setOrders(response.results);
      setTotalItems(response.total);
    } catch (error) {
      console.log("Error", error);
    }
  }, [currentPage, filterOrders, form, id, pageSize]);

  const handleSubmitFilter = (params) => {
    const formValues = form.getFieldsValue();

    setFilterOrders({
      ...filterOrders,
      keyWord: params?.keyWord || formValues?.keyWord || null,
      orderNumber: params?.orderNumber || formValues?.orderNumber || null,
      status: params?.status || formValues?.status || null,
      // startDate:
      //   params?.createdAt?.[0] && formValues?.createdAt?.[0]
      //     ? dayjs(params?.createdAt?.[0]).toISOString() ??
      //       dayjs(formValues?.createdAt?.[0]).toISOString()
      //     : null,
      // endDate:
      //   params?.createdAt?.[1] && formValues?.createdAt?.[1]
      //     ? dayjs(params?.createdAt?.[1]).toISOString() ??
      //       dayjs(formValues?.createdAt?.[1]).toISOString()
      //     : null,
    });

    setCurrentPage(1);
  };

  const handleResetFilter = () => {
    setFilterOrders({
      keyWord: null,
      orderNumber: null,
      status: null,
      // startDate: null,
      // endDate: null,
    });
    setCurrentPage(1);
  };

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  const tableColumns = [
    {
      key: "keyWord",
      title: "User information",
      hideInTable: true,
      renderFormItem: (
        _,
        { type, defaultRender, formItemProps, fieldProps, ...rest },
        form
      ) => {
        if (type === "form") {
          return null;
        }
        return (
          <ProFormText
            name="searchFull"
            placeholder="Enter name, email or phone number"
          />
        );
      },
    },
    {
      key: "orderNumber",
      title: "Order number",
      dataIndex: "orderNumber",
    },
    {
      key: "useName",
      title: "User name",
      dataIndex: ["user", "fullName"],
      search: false,
      sorter: (current, next) =>
        SortingText(current.user.fullName, next.user.fullName),
    },
    {
      key: "userEmail",
      title: "User email",
      dataIndex: ["user", "email"],
      search: false,
      sorter: (current, next) =>
        SortingText(current.user.email, next.user.email),
    },
    {
      key: "phoneNumber",
      title: "Phone number",
      dataIndex: ["user", "phoneNumber"],
      search: false,
      sorter: (current, next) =>
        SortingText(current.user.phoneNumber, next.user.phoneNumber),
    },
    {
      key: "id",
      title: "Transaction ID",
      dataIndex: "id",
      search: false,
    },
    {
      key: "totalPrice",
      title: "Total price",
      dataIndex: "totalPrice",
      search: false,
      sorter: (current, next) =>
        SortingText(current.totalPrice, next.totalPrice),
    },
    {
      key: "paymentOption",
      title: "Payment",
      dataIndex: "paymentOption",
      search: false,
      sorter: (current, next) =>
        SortingText(current.totalPrice, next.totalPrice),
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      sorter: (current, next) => SortingText(current.status, next.status),
      valueEnum: {
        FAILURE: {
          text: "FAILURE",
        },
        SUCCESS: {
          text: "SUCCESS",
        },
        CANCELED: {
          text: "CANCELED",
        },
      },
    },
    {
      key: "boughtAt",
      title: "Purchase time",
      dataIndex: "boughtAt",
      valueType: "dateTimeRange",
      search: false,
      fieldProps: {
        placeholder: ["Start time", "End time"],
        showTime: false,
      },
      sorter: (current, next) => SortingDate(current.boughtAt, next.boughtAt),
      render: (_, record) => {
        if (record.boughtAt)
          return (
            <Text>
              {dayjs(record.boughtAt)
                .tz("Asia/Kuwait")
                .format("YYYY/MM/DD HH:mm")
                .toString()}
            </Text>
          );

        return <span>-</span>;
      },
    },
    {
      key: "createdAt",
      title: "Created At",
      dataIndex: "createdAt",
      valueType: "dateTimeRange",
      search: false,
      fieldProps: {
        placeholder: ["Start time", "End time"],
        showTime: false,
      },
      sorter: (current, next) => SortingDate(current.createdAt, next.createdAt),
      render: (_, record) => {
        return (
          <Text>
            {dayjs(record.createdAt)
              .tz("Asia/Kuwait")
              .format("YYYY/MM/DD HH:mm")
              .toString()}
          </Text>
        );
      },
    },
    {
      key: "updatedAt",
      title: "Updated At",
      dataIndex: "updatedAt",
      hideInTable: true,
      search: false,
      sorter: (current, next) => SortingDate(current.updatedAt, next.updatedAt),
      render: (_, record) => {
        return <Text>{dayjs(record.updatedAt).fromNow()}</Text>;
      },
    },
    {
      key: "actions",
      title: "Actions",
      search: false,
      width: 100,
      render: (_, record) => [
        <div key={record.id} className="actions-wrapper">
          <Button
            className="icon-button"
            icon={<EditFilled />}
            onClick={() => navigate(`/orders/${record?.id}/edit`)}
          />
        </div>,
      ],
    },
  ];

  return (
    <PageContainer title="All orders">
      <ProTable
        rowKey="id"
        request={({ pageSize, current }, sort, filter) => {
          setPageSize(pageSize);

          return {
            // success,
            // total,
            filter,
          };
        }}
        onSubmit={(params) => handleSubmitFilter(params)}
        onReset={handleResetFilter}
        scroll={{ x: 1600 }}
        columns={tableColumns}
        dataSource={orders}
        // toolBarRender={() => [
        //   <Button
        //     key="button"
        //     icon={<PlusOutlined />}
        //     onClick={() => {
        //       navigate("/clubs/new");
        //     }}
        //     type="primary"
        //   >
        //     New Club
        //   </Button>,
        // ]}
        pagination={{
          pageSize: pageSize,
          total: totalItems,
          onChange: (page) => setCurrentPage(page),
        }}
        search={{
          form: form,
          labelWidth: "auto",
        }}
      ></ProTable>
    </PageContainer>
  );
};

export default EventOrders;
